<template>
  <div class="CEESAR-Explandable">
      <div :class="['CEESAR-ExplandableLabel', isExpanded? 'CEESAR-ExplandableLabel-Expanded' : 'CEESAR-ExplandableLabel-Collapsed']"
            v-on:click="switchState">
          {{label}}
      </div>

      <div :class="['CEESAR-ExplandableContent', isExpanded? 'CEESAR-ExplandableContent-Expanded' : 'CEESAR-ExplandableContent-Collapsed']">
        <slot />
      </div>
  </div>
</template>

<script>
export default {
    name: "CEESAR-Explandable",
    props:{
        label:{type: String, required: true}
    },
    data(){
        return{
            isExpanded: false
        };
    },mounted(){

    },
    methods:{
        switchState(e){
            let eContent = e.target.nextSibling;

            if(eContent.style.maxHeight)
                eContent.style.maxHeight = null;
            else
                eContent.style.maxHeight = eContent.scrollHeight + 5 + "px";

            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>

<style>

</style>